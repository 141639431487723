<template>
  <er-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :modal ="false"
    width="35%"
    :loading="loading"
    @open="handleOpenDialog"
    :title="'Group Settings'"
    class="group_setting_dialog_box"
    custom-class="st-dialog-body-width-50 dialog-body-y-20"
  >
    <el-form :model="group_settings_form" label-width="120px" >
      <el-form-item label="Customer Name" prop="customer_name" class="form-item-margin-bottom-0">
        <er-select
          v-model="group_settings_form.customer_name"
          remote
          reserve-keyword
          :remote-method="handleChange"
          @change="handelOptionChange"
          filterable placeholder="Select"
          size="small"
        >
          <el-option
            v-for="(item, index) in updateLoadUserOptions"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </er-select>
        <div class="err-msg" v-if="err_validation.customer_name">Customer Name is required</div>
      </el-form-item>
      <el-form-item label="Locations" prop="locations" class="form-item-margin-bottom-0">
        <er-select
          v-model="group_settings_form.locations"
          :showSelectAll="true"
          reserve-keyword
          collapse-tags
          @change="handelLocationChange"
          multiple
          filterable
          placeholder="Select"
          size="small"
        >
          <el-option
            v-for="(item, index) in location_options"
            :key="index"
            :label="item.name"
            :value="item._id">
          </el-option>
        </er-select>
      </el-form-item>
      <el-form-item label="ShrimpTalk Id" prop="ST_ids" class="form-item-margin-bottom-0">
        <er-select
          v-model="group_settings_form.ST_ids"
          :showSelectAll="true"
          reserve-keyword
          collapse-tags
          @change="handelStIdsOptionChange"
          multiple
          filterable
          placeholder="Select"
          size="small"
        >
          <el-option
            v-for="(item, index) in st_options"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </er-select>
        <div class="err-msg" v-if="err_validation.ST_ids">ShrimpTalk Id is required</div>
      </el-form-item>
      <el-form-item label="Block ST">
        <el-switch
          :value="group_settings_form.block_ST"
          @input="handleChangeValue"
          >
        </el-switch>
      </el-form-item>
    </el-form>
    <layout-toolbar slot="footer" justify="center">
    <er-button
      size="mini"
      btnType="save"
      :showLabel="true"
      :showIcon="true"
      @click="submitResourceDetails"
      :loading="loading"
      :disabled="loading"
    ></er-button>
    <er-button
      size="mini"
      btnType="cancel"
      :showLabel="true"
      @click="handleCloseDialog"
    ></er-button>
  </layout-toolbar>
  </er-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  props: {
    showDialog: {
      default: false
    }
  },
   mixins: [filtersMixin],
  data: function() {
    return {
      loading: false,
      st_options: [],
      // location_options: this.getUserLocationsData,
      options: [],
      group_settings_form: {
        customer_name: '',
        ST_ids: [],
        locations: [],
        block_ST: false
      },
      query: {
        page: 1,
        limit: 30
      },
      debounce: null,
      err_validation: { customer_name: false, ST_ids: false }
    }
  },
  mounted() {
    // this.getCustomersData();
  },
  computed: {
    ...mapGetters("superadmin", {
      getCustomers: "getStGrpSettingUsers",
      getUserLocationsData: "getUserLocationsData",
    }),
    location_options() {
        const locationsArray = this.$lodash.cloneDeep(this.getUserLocationsData);
      return this.group_settings_form.customer_name ? locationsArray.reduce((acc, curr) => {
          curr.name = this.ftm__capitalize(curr.name);
          acc.push(curr);
          return acc;
        }, []) : [];
    },
    updateLoadUserOptions() {
     return this.loadUserOptions();
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog('close');
      }
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllCustomers: "fetchAllDeviceUsers",
      fetchACustomerDetails: "fetchAUserDetails",
      updateBlockStStatus: "updateBlockStStatus",
      fetchCustomerPondShrimptalksBlockData: "fetchCustomerPondShrimptalksBlockData",
      fetchUserLocations: "fetchUserLocations",
    }),
    loadUserOptions() {
      this.options = [];
      this.getCustomers.map(data => {
        this.options.push({
          value: data._id,
          label: data.full_name + " -- " + data.email
        })
      })
      return this.query.user_name !== undefined ? this.options : [];
    },
    async submitResourceDetails() {
      if (this.group_settings_form.customer_name === '') {
        this.err_validation.customer_name = true;
      }

      if (this.group_settings_form.ST_ids.length === 0) {
        this.err_validation.ST_ids = true;
      }

      if (this.group_settings_form.customer_name !== "" &&
        this.group_settings_form.ST_ids.length > 0) {
          // this.getCustomersData();
        try {
          const confirmMessage = `Your are ${
              this.group_settings_form.block_ST ? "blocking" : "unblocking"
            } the selected ShrimpTalk, Are you sure?`;
          await this.$confirm(confirmMessage, "Warning", {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }).then(async () => {
            const payload = {
              shrimp_talk_ids: this.group_settings_form.ST_ids,
              block_shrimp_talk: this.group_settings_form.block_ST
            }
            await this.updateBlockStStatus(payload);
            this.$notify({
              type: "success",
              title: "Success",
              message: this.group_settings_form.block_ST ? "ShrimpTalk are Blocked" : "ShrimpTalk are Unblocked"
            });
            this.$emit('close_dialog', false);
            delete this.query.user_name;
            // this.getCustomersData();
          })
          this.loading = true;
          // await this.handleLoadData(this.queryParams);
        } catch (error) {
          if (error !== "cancel") {
            const errorCode = error.response.data.status_code;
            let message;
            switch (errorCode) {
              case 409:
                message = error.response.data.message;
                this.$notify({
                  type: "error",
                  title: "Failed",
                  message
                });
                break;
              default:
                this.ehm__errorMessages(error, true);
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async getCustomersData() {
      await this.fetchAllCustomers({
        infinite_scroll: "grp_settings",
        params: this.query
      });
    },
    handleChangeValue(event) {
      this.group_settings_form.block_ST = event;
    },
    async handelOptionChange(event) {
      this.err_validation.customer_name = false;
      try {
        this.loading = true;
        this.st_options = [];
        this.group_settings_form.locations = [];
        this.group_settings_form.ST_ids = [];
        // const customer_data = await this.fetchACustomerDetails({
        //   user_id: event,
        //   params: { include_devices: true }
        // });
        await this.fetchUserLocations({ userId: event, noLocation: true });
      //   const customer_data = await this.fetchCustomerPondShrimptalksBlockData({
      //     order_by: "utc_last_communicated_at",
      //     order_type: "desc",
      //     user_id: event,
      //     get_all: true
      // });
      //   var selectedStIds = [];
      //   customer_data.data.shrimp_talks.map(data => {
      //     this.st_options.push({ label: "ST" + data.v1_code, value: data._id });
      //     selectedStIds.push(data._id);
      //     this.err_validation.ST_ids = false;
      //   })
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
      // if (selectedStIds) {
      //   this.group_settings_form.ST_ids = selectedStIds;
      // }
    },
    async handelLocationChange(data) {
      try {
        this.loading = true;
        var unAssigned = false;
        const dataCopy = this.$lodash.cloneDeep(data);
        const index = dataCopy.indexOf('unAssigned');
        if (index > -1) { // only splice array when item is found
          dataCopy.splice(index, 1);
          unAssigned = true;
        }
        // const customer_data = await this.fetchACustomerDetails({
        //   user_id: event,
        //   params: { include_devices: true }
        // });
        // await this.fetchUserLocations({ userId: event, noLocation: false });
        const customer_data = await this.fetchCustomerPondShrimptalksBlockData({
          order_by: "user_name",
          order_type: "asc",
          user_id: this.group_settings_form.customer_name,
          un_assigned_only: unAssigned,
          location_ids: dataCopy,
          get_all: true
      });
        var selectedStIds = [];
        this.st_options = [];
        customer_data.data.shrimp_talks.map(data => {
          this.st_options.push({ label: "ST" + data.v1_code, value: data._id });
          selectedStIds.push(data._id);
          this.err_validation.ST_ids = false;
        })
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
      if (selectedStIds) {
        this.group_settings_form.ST_ids = selectedStIds;
      }
    },
    handleCloseDialog(event) {
      delete this.query.user_name;
      // this.getCustomersData();
      this.group_settings_form = {
        customer_name: '',
        ST_ids: [],
        locations: [],
        block_ST: false
      };
      this.loading = false;
      this.$emit('close_dialog', event);
    },
    handleChange(string) {
      if (string === "") {
        this.query.page = 1;
        this.query.limit = 30;
        delete this.query.user_name;
        this.err_validation.customer_name = true;
      } else {
        this.query.user_name = string.trim();
        this.err_validation.customer_name = false;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.getCustomersData()
        }, 500);
        this.loadUserOptions();
      }
    },
    handleOpenDialog() {
      this.group_settings_form = {
        customer_name: '',
        ST_ids: [],
        locations: [],
        block_ST: false
      };
    },
    handelStIdsOptionChange(value) {
      if (value.length > 0) {
        this.err_validation.ST_ids = false;
      } else {
        this.err_validation.ST_ids = true;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-select {
    display: block !important;
    position: relative;
  }
</style>
<style lang="scss">
.dialog-body-y-20 {
    .el-dialog__body {
      padding: 0px 15px !important;
    }
  }
  .st-dialog-body-width-50 {
        border-radius: 15px;
  }
.form-item-margin-bottom-0 {
  margin-bottom: 10px !important;
  .err-msg {
    color: #f54e5e;
    font-size: 10px;
    line-height: 1.5;
  }
}
</style>
