<template>
  <div class="account-manager-customer-pond-summary" >
    <!---      toolbar-layout="year_filter,last_updated, plan_type, plan_name"-->
    <layout-dealer-card
      search-property="year"
      toolbar-layout="year_filter,download_btn,blockST_btn,device_toggle"
      @loadData="handleSearch"
      @onDownloadData="downloadFnc"
      @toggleChange="handleEnableFeature"
      v-loading="loading"
      :isSTBlock="isSTBlock"
      @add-group-settings-click="handlegroupSettingsClick"
    >
      <template v-slot:heading>
        <span class="material-icons-round" style="color: #33a02c">
          card_membership
        </span>
        <p style="margin-left: 10px">
          {{ ftm__capitalize($t("dealer.subscriptions")) }}
        </p>
        <div class="selection-box" v-if="!isSTBlock">
          <er-select
            size="mini"
            remote
            reserve-keyword
            :remote-method="handleChange"
            v-model="customer"
            filterable
            placeholder="Select Customer"
            @change="handleCustomerChange"
          >
            <el-option
              v-for="(item, index) in updateLoadUserOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </er-select>
        </div>
        <div v-if="!isSTBlock">
          <!-- <div class="labels-adjustment">Location Filter :</div> -->
          <er-select
            size="mini"
            v-model="location"
            placeholder="Select Location"
            :showSelectAll="true"
            collapse-tags
            multiple
            filterable
            class="cust-level-selection-box"
            @change="handleLocationChange"
          >
            <el-option
              v-for="(item, index) in locationsData"
              :key="index"
              :label="`${item.name}`"
              :value="item._id"
            >
            </el-option>
          </er-select>
        </div>
        <div class="selection-box" v-if="isSTBlock">
          <er-select
            size="mini"
            remote
            reserve-keyword
            :remote-method="handleChange"
            v-model="customer_device"
            filterable
            placeholder="Select Customer"
            @change="handleCustomerChange"
          >
            <el-option
              v-for="(item, index) in updateLoadUserOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </er-select>
        </div>
        <div v-if="isSTBlock">
          <!-- <div class="labels-adjustment">Location Filter :</div> -->
          <er-select
            size="mini"
            v-model="location_device"
            placeholder="Select Location"
            :showSelectAll="true"
            collapse-tags
            multiple
            filterable
            class="cust-level-selection-box"
            @change="handleLocationChange"
          >
            <el-option
              v-for="(item, index) in locationsData"
              :key="index"
              :label="`${item.name}`"
              :value="item._id"
            >
            </el-option>
          </er-select>
        </div>
        <div class="search-btn" v-if="isSTBlock">
          <el-button size="mini" @click="LocationFilterData">Submit</el-button>
          <!-- <el-button type="success" size="mini" @click="LocationFilterData">
              <i class="el-icon-search"></i>
            </el-button> -->
        </div>
        <div class="cust-level-box">
          <div class="labels-adjustment" v-if="!isSTBlock"> Dealer Name:</div>
          <p style="font-size: 11px;" v-if="!isSTBlock">{{getCustomersDealerAndGroupDetails.dealer_name}}</p>
        </div>
        <div class="cust-level-box">
          <div class="labels-adjustment" v-if="!isSTBlock"> Group Name:</div>
          <p style="font-size: 11px;" v-if="!isSTBlock">{{getCustomersDealerAndGroupDetails.group_name}}</p>
        </div>
        <!-- <div class="toggle-box" style="text-align: right">
          <span style="font-size: 12px;font-weight: 700;">Devices</span>
          <el-switch
            :value="isSTBlock"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @input="handleEnableFeature($event, 'BlockSTTable')"
          ></el-switch>
        </div> -->
      </template>

      <template slot="sub-heading"> Summary </template>
      <template slot="table" v-if="isSTBlock">
        <el-table
          :data="STBlockTableData"
          style="width: 100%; height: calc(100vh - 175px)"
          size="mini"
          v-loading="STTableLoading"
        >
        <!-- :summary-method="getSummaries"
          show-summary
          :cell-class-name="getCellClass" -->
          <el-table-column
            v-for="(col, index) of STBlockColumns"
            :prop="col.prop"
            :label="col.label"
            :key="index"
            :min-width="col.width"
            :align="col.align"
          >
            <template slot="header" slot-scope="scope">
              {{ STBlockColumns[scope.$index].label }}
              <span v-if="STBlockColumns[scope.$index].info">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="STBlockColumns[scope.$index].info"
                  placement="top"
                  ><i class="el-icon-info icon"
                /></el-tooltip>
              </span>
            </template>
            <template slot-scope="scope">
              <span v-if="col.prop == 'pond'" class="location-name">
                <el-button
                  class="text-button"
                  type="text"
                >
                <!-- @click="showPondSTDetails(scope.row[col.prop])" -->
                  {{ scope.row[col.prop].name }}
                </el-button>
              </span>
              <span v-else-if="col.prop == 'location'" class="location-name">
                {{ scope.row[col.prop].name }}
              </span>
              <span v-else-if="col.prop == 'block_St'" class="location-name">
                <el-switch
                  :value="scope.row[col.prop]"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @input="handleDisableFeature($event, scope.row)"
                ></el-switch>
              </span>
               <span v-else-if="col.prop == 'start_date'" class="location-name">
                {{ handleDate(scope.row[col.prop]) }}
              </span>
              <span v-else-if="col.prop == 'end_date'" class="location-name">
                {{ handleDate(scope.row[col.prop]) }}
              </span>
              <span v-else-if="propsForAmountFormat[col.prop]">
                {{ getLocaleString(scope.row[col.prop]) || "-" }}
              </span>
              <span v-else :class="col.value_type">
                {{ scope.row[col.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="table" v-else>
        <el-table
          :data="tableData.data"
          style="width: 100%; height: calc(100vh - 225px)"
          :summary-method="getSummaries"
          show-summary
          :cell-class-name="getCellClass"
          size="mini"
        >
          <el-table-column
            v-for="(col, index) of columns"
            :prop="col.prop"
            :label="col.label"
            :key="index"
            :min-width="col.width"
            :align="col.align"
          >
            <template slot="header" slot-scope="scope">
              {{ columns[scope.$index].label }}
              <span v-if="columns[scope.$index].info">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="columns[scope.$index].info"
                  placement="top"
                  ><i class="el-icon-info icon"
                /></el-tooltip>
              </span>
            </template>
            <template slot-scope="scope">
              <span v-if="col.prop == 'pond'" class="location-name">
                <el-button
                  class="text-button"
                  type="text"
                  @click="showPondSTDetails(scope.row[col.prop])"
                >
                  {{ scope.row[col.prop].name }}
                </el-button>
              </span>

              <span v-else-if="col.prop == 'location'" class="location-name">
                {{ scope.row[col.prop].name }}
              </span>
              <span v-else-if="propsForAmountFormat[col.prop]">
                {{ getLocaleString(scope.row[col.prop]) || "-" }}
              </span>
              <span v-else :class="col.value_type">
                {{ scope.row[col.prop] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="table-legends">
        <div>
          <el-row type="flex" align="middle" >
            <el-col :sm="6">
              <div class="table-info text-left" v-if="!isSTBlock">
                <span>STs without subscription : </span>
                <span>
                  <strong> {{ tableData.total_unnassinged_count }} </strong>
                </span>
              </div>
            </el-col>
            <el-col :sm="12">
              <div class="table-pagination" v-if="isSTBlock&&totalRecords>0">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-sizes="[25, 50, 75, 100]"
                  :page-size="pageSize"
                  layout="prev, pager, next, total, sizes"
                  :total="totalRecords">
                </el-pagination>
              </div>
            </el-col>
            <el-col :sm="6">
              <div class="table-legends" v-if="!isSTBlock">
                <div class="actuals">
                  <span class="icon"></span>
                  <span>Actuals </span>
                </div>
                <div class="projections">
                  <span class="icon"></span>
                  <span>Projections </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </layout-dealer-card>

    <component
      :is="`shrimpTalkGroupSettingsDialog`"
      :showDialog="group_setting_dialogVisible"
      @close_dialog="closeGroupSettingsDialog"
    ></component>
    <el-dialog
      class="pond-details-dialog"
      :title="selectedPondName"
      :visible.sync="dialogPondDetailsVisible"
      width="55%!important"
      top="20px"
    >
      <pondDetails
        v-if="dialogPondDetailsVisible"
        :pond="selectedPondId"
        :pond_name="selectedPondName"
      >
      </pondDetails>
    </el-dialog>

    <el-dialog
      class="pond-details-dialog"
      :title="selectedPondName"
      :visible.sync="dialogUnassignedPondDetailsVisible"
      width="800px"
      top="20px"
    >
      <unAssignedPondDetails
        v-if="dialogUnassignedPondDetailsVisible"
        :pond="selectedPondId"
        :pond_name="selectedPondName"
        :unassigned_data="tableData.total_unnassinged_details"
      >
      </unAssignedPondDetails>
    </el-dialog>
  </div>
</template>

<script>
import layoutDealerCard from "@/components/dealer/layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import moment from "moment";
import redirectsMixin from "@/mixins/redirectsMixin";
import shrimpTalkGroupSettingsDialog from "@/components/superadmin/shrimp-talk/shrimpTalkGroupSettingsDialog.vue"

import pondDetails from "./pondDetails";
import unAssignedPondDetails from "./unAssignedPondDetails";

export default {
  mixins: [errorHandlerMixin, filtersMixin, redirectsMixin],
  components: {
    layoutDealerCard,
    pondDetails,
    unAssignedPondDetails,
    shrimpTalkGroupSettingsDialog
  },
  data: function() {
    return {
      loading: false,
      STTableLoading: false,
      columns: [
        {
          label: "Location",
          prop: "location",
          sort: true,
          width: "130",
          fixed: "left"
        },
        {
          label: "Pond",
          prop: "pond",
          sort: false,
          width: "90",
          fixed: "left",
          action: {
            type: "route-change",
            toRoute: "/admin/subscriptions/pond-details"
          }
        },
        {
          label: "Total STs",
          prop: "total_sts",
          sort: true,
          width: "70",
          fixed: "left",
          align: "center"
        },
        {
          label: "Installation Date",
          prop: "installation_date",
          sort: true,
          width: "120",
          align: "center",
          info: "Date on which device is assigned to the pond"
        },
        {
          label: "Start Date",
          prop: "start_date",
          sort: true,
          width: "100",
          align: "center",
          info:
            "Date on which subscription gets started which is based on device in Automode & Feeder dispenses feed"
        },
        {
          label: "End Date",
          prop: "end_date",
          sort: true,
          width: "100",
          align: "center",
          info: "Expiry of the subscription plan"
        },
        {
          label: "Jan",
          prop: "jan",
          sort: true,
          align: "center"
        },
        {
          label: "Feb",
          prop: "feb",
          sort: true,
          align: "center"
        },
        {
          label: "Mar",
          prop: "mar",
          sort: true,
          align: "center"
        },
        {
          label: "Apr",
          prop: "apr",
          sort: true,
          align: "center"
        },
        {
          label: "May",
          prop: "may",
          sort: true,
          align: "center"
        },
        {
          label: "Jun",
          prop: "jun",
          sort: true,
          align: "center"
        },
        {
          label: "Jul",
          prop: "jul",
          sort: true,
          align: "center"
        },
        {
          label: "Aug",
          prop: "aug",
          sort: true,
          align: "center"
        },
        {
          label: "Sep",
          prop: "sep",
          sort: true,
          align: "center"
        },
        {
          label: "Oct",
          prop: "oct",
          sort: true,
          align: "center"
        },
        {
          label: "Nov",
          prop: "nov",
          sort: true,
          align: "center"
        },
        {
          label: "Dec",
          prop: "dec",
          sort: true,
          align: "center"
        },
        {
          label: "Total",
          prop: "total",
          sort: true,
          align: "center",
          fixed: "right",
          width: "100"
        }
      ],
      STBlockColumns: [
        {
          label: "Location",
          prop: "location",
          sort: true,
          width: "100",
          fixed: "left"
        },
        {
          label: "Pond",
          prop: "pond",
          sort: false,
          width: "80",
          fixed: "left",
          // action: {
          //   type: "route-change",
          //   toRoute: "/dealer/subscriptions/pond-details"
          // }
        },
        {
          label: "Alias Name",
          prop: "alias_name",
          sort: true,
          width: "80",
          fixed: "left",
          align: "center"
        },
        {
          label: "ST ID",
          prop: "St_id",
          sort: true,
          width: "80",
          fixed: "left",
          align: "center"
        },
        {
          label: "Block ST",
          prop: "block_St",
          sort: true,
          width: "80",
          fixed: "left",
          align: "center"
        },
        // {
        //   label: "Installation Date",
        //   prop: "installation_date",
        //   sort: true,
        //   width: "90",
        //   align: "center",
        //   info: "Date on which device is assigned to the pond"
        // },
        {
          label: "Start Date",
          prop: "start_date",
          sort: true,
          width: "90",
          align: "center",
          info:
            "Date on which subscription gets started which is based on device in Automode & Feeder dispenses feed"
        },
        {
          label: "End Date",
          prop: "end_date",
          sort: true,
          width: "90",
          align: "center",
          info: "Expiry of the subscription plan"
        },
      ],
      tableData: {
        year: 2022,
        data: [],
        total_unnassinged_count: 0,
        total_unnassinged_details: 0
      },
      group_setting_dialogVisible: false,
      searchString: "",
      allCustomers: [],
      customer: "",
      customer_device: "",
      sum: [],
      start_date: "2021-01-01",
      end_date: "2021-12-31",
      // Dialog Value
      dialogPondDetailsVisible: false,
      dialogUnassignedPondDetailsVisible: false,
      selectedPondId: "",
      selectedPondName: "",
      propsForAmountFormat: {
        jan: true,
        feb: true,
        mar: true,
        apr: true,
        may: true,
        jun: true,
        jul: true,
        aug: true,
        sep: true,
        oct: true,
        nov: true,
        dec: true,
        total: true
      },
      isSTBlock: false,
      location: [],
      location_device: [],
      oldTableData: {
        year: 2022,
        data: [],
        total_unnassinged_count: 0,
        total_unnassinged_details: 0
      },
      // usersData: [],
      currentPage: 1,
      pageSize: 25,
      totalRecords: 0,
      query: {
        page: 1,
        limit: 30
      },
      debounce: null,
      userLocationsData: [],
    };
  },
  computed: {
     ...mapGetters("superadmin", {
       getDealerCustomerSubscriptionReceivables:
        "getDealerCustomerSubscriptionReceivables",
      getDealerCustomerSubscriptionUnassignedDetails:
        "getDealerCustomerSubscriptionUnassignedDetails",
      getDealerCustomerSubscriptionUnassignedCount:
        "getDealerCustomerSubscriptionUnassignedCount",
        getUsers: "getUsers",
        getDeviceUsers: "getDeviceUsers",
        getUserLocationsData: "getUserLocationsData",
        getCustomersDealerAndGroupDetails: "getCustomersDealerAndGroupDetails",
        getCustomerPondBlockShrimptalksData: "getCustomerPondBlockShrimptalksData"
    }),
    locationsData() {
      if (this.isSTBlock) {
        const locationsArray = this.$lodash.cloneDeep(this.getUserLocationsData);
        return this.customer_device ? locationsArray.reduce((acc, curr) => {
          curr.name = this.ftm__capitalize(curr.name);
          acc.push(curr);
          return acc;
        }, []) : [];
      } else {
        return this.customer ? this.userLocationsData : [];
      }
    },
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    },
    STBlockTableData() {
      return this.getCustomerPondBlockShrimptalksData;
    },
    updateLoadUserOptions() {
     return this.loadUserOptions();
    },
  },
  async mounted() {
    this.tableData.year = new Date().getFullYear();
    // this.usersData = JSON.parse(localStorage.getItem("SubscriptionUserData"));
    this.start_date = moment()
      .startOf("year")
      .format("YYYY-MM-DD");
    this.end_date = moment()
      .endOf("year")
      .format("YYYY-MM-DD");

    this.customer = await this.$route.query.customer;
  },
  methods: {
    // ...mapActions("accountManager", {
    //   fetchAllSubscriptions: "fetchAllSubscriptions",
    //   // fetchCustomerSubscriptionReceivables:
    //   //   "fetchCustomerSubscriptionReceivables",
    //   fetchDealerUsersAndDevices: "fetchDealerUsersAndDevices"
    // }),
    ...mapActions("superadmin", {
      fetchCustomerSubscriptionReceivables:
        "fetchCustomerSubscriptionReceivables",
      fetchAllCustomers: "fetchAllUsers",
      fetchAllDeviceUsers: "fetchAllDeviceUsers",
      fetchUserLocations: "fetchUserLocations",
      updateBlockStStatus: "updateBlockStStatus",
      fetchCustomerPondShrimptalksBlockData: "fetchCustomerPondShrimptalksBlockData",
      fetchCustomerPondShrimptalksBlockDataForDownload: "fetchCustomerPondShrimptalksBlockDataForDownload"
    }),
    // ...mapMutations("superadmin", {
    //   SET_CUSTOMER_POND_SHRIMPTALKS_BLOCK_DETAILS: "SET_CUSTOMER_POND_SHRIMPTALKS_BLOCK_DETAILS",
    //   SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES: "SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES",
    //   SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT: "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT",
    //   SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS: "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS",
    //   SET_DEALER_GROUP_DETAILS: "SET_DEALER_GROUP_DETAILS"
    // }),
    // async handleSTBlockDataLoad() {
    //   const response = await this.fetchCustomerPondShrimptalksBlockData({
    //       order_by: "utc_last_communicated_at",
    //       order_type: "desc",
    //       user_id: this.customer,
    //       page: this.currentPage,
    //       limit: this.pageSize
    //   });
    //     this.totalRecords = response.data.total;
    // },
    handleChange(string) {
      if (string !== "") {
        this.query.user_name = string.trim();
        // this.err_validation.customer_name = false;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.getCustomersData()
        }, 500);
        this.loadUserOptions();
      }
    },
    async getCustomersData() {
     this.isSTBlock ? await this.fetchAllDeviceUsers({
        infinite_scroll: "false",
        params: this.query
      }) : await this.fetchAllCustomers({
        infinite_scroll: false,
        params: this.query
      });
    },
    loadUserOptions() {
      this.options = [];
     this.isSTBlock ? this.getDeviceUsers.map(data => {
        this.options.push({
          value: data._id,
          label: data.full_name
        })
      })
      : this.getUsers.map(data => {
        this.options.push({
          value: data._id,
          label: data.full_name
        })
      })
      return this.query?.user_name !== undefined ? this.options : [];
    },
    async handleLoadData() {
      try {
        this.loading = true;
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        for (let i = 6; i < 18; i++) {
          const monthIndex = i - 6;
          this.columns[i].is_month = true;
          if (this.tableData.year > currentYear) {
            this.columns[i].value_type = "projections";
          } else if (
            this.tableData.year === currentYear &&
            monthIndex > currentMonth
          ) {
            this.columns[i].value_type = "projections";
          } else {
            this.columns[i].value_type = "actuals";
          }
        }
        // await this.fetchAllCustomers({
        //   infinite_scroll: false,
        //   params: {
        //     include_devices_count: false,
        //     email_not_verified_users_only: false,
        //     order_by: "first_name",
        //     order_type: "asc",
        //     get_all: true,
        //     status: ["ADMIN"]
        //   }
        // });
        this.allCustomers = this.getUsers;
        console.log('r1')
        await this.fetchCustomerSubscriptionReceivables({
          id: this.isSTBlock ? this.customer_device : this.customer,
          params: {
            order_by: "first_name",
            order_type: "asc",
            get_all: true,
            start_date: this.start_date,
            end_date: this.end_date
          }
        });
        console.log('r2')

        const assignedData = this.prepareAssignedData();

        this.tableData.data = this.updateTableData(assignedData);

        this.tableData.total_unnassinged_count = this.getDealerCustomerSubscriptionUnassignedCount;

        this.oldTableData.data = this.updateTableData(assignedData);
        this.oldTableData.total_unnassinged_count = this.getDealerCustomerSubscriptionUnassignedCount;

        this.loading = false;
      } catch (err) {
        console.log('heeeyy')
        await this.emptyTableData()
        // await this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async emptyTableData() {
      this.location = [];
      this.location_device = [];
      this.userLocationsData = [];
      this.tableData.data = [];
      this.tableData.total_unnassinged_count = 0;
      this.oldTableData.data = [];
      this.oldTableData.total_unnassinged_count = 0;
    },
    async LocationFilterData() {
      this.STTableLoading = true;
      try {
        var locations = this.isSTBlock ? [...this.location_device] : [...this.location];
        var unAssigned = false;
        const index = locations.indexOf('unAssigned');
        if (index > -1) { // only splice array when item is found
          locations.splice(index, 1);
          unAssigned = true; // 2nd parameter means remove one item only
        }
        // if (this.location.includes('unAssigned')) {
          const response = await this.fetchCustomerPondShrimptalksBlockData({
            order_by: "user_name",
            order_type: "asc",
            user_id: this.isSTBlock ? this.customer_device : this.customer,
            un_assigned_only: unAssigned,
            location_ids: locations,
            page: this.currentPage,
            limit: this.pageSize
          });
          this.totalRecords = response.data.total;
        // } else {
        //   const response = await this.fetchCustomerPondShrimptalksBlockData({
        //     order_by: "user_name",
        //     order_type: "asc",
        //     user_id: this.customer,
        //     location_ids: this.location,
        //     page: this.currentPage,
        //     limit: this.pageSize
        //   });
        //   this.totalRecords = response.data.total;
        // }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        // this.location.push("unAssigned");
        this.STTableLoading = false
      }
    },
    prepareAssignedData() {
      console.log("this.getDealerCustomerSubscriptionReceivables0000===", this.getDealerCustomerSubscriptionReceivables);
      const assignedData = this.$lodash.cloneDeep(this.getDealerCustomerSubscriptionReceivables);

      if (assignedData.length > 0 && Object.keys(this.getDealerCustomerSubscriptionUnassignedDetails).length !== 0) {
        this.tableData.total_unnassinged_details = {
          ...this.getDealerCustomerSubscriptionUnassignedDetails
        };

        this.tableData.total_unnassinged_details.months = Object.values(
          this.tableData.total_unnassinged_details.months
        );
        this.tableData.total_unnassinged_details.location_name = "";
        this.tableData.total_unnassinged_details.location_id = "";
        this.tableData.total_unnassinged_details.pond_name = "Unassigned";
        this.tableData.total_unnassinged_details.pond_id = "UNASSIGNED";

        assignedData.push(this.tableData.total_unnassinged_details);
      }
      return assignedData;
    },
    showPondSTDetails(pondDetails) {
      if (pondDetails._id === "UNASSIGNED") {
        this.selectedPondId = pondDetails._id;
        this.selectedPondName = pondDetails.name;
        this.dialogUnassignedPondDetailsVisible = true;
        this.dialogPondDetailsVisible = false;
      } else {
        this.selectedPondId = pondDetails._id;
        this.selectedPondName = pondDetails.name;
        this.dialogPondDetailsVisible = true;
        this.dialogUnassignedPondDetailsVisible = false;
      }
    },
    getCellClass({ row, column, rowIndex, columnIndex }) {
      const selectedColumn = this.columns[columnIndex];
      return selectedColumn.value_type || "";
    },
    downloadFnc() {
      if (this.isSTBlock) {
        this.downloadUserSTTableData();
      } else {
        this.downloadTableData();
      }
    },
    downloadTableData(data) {
      let csv = this.columns.map((e) => e.label).join(",") + "\n";

      csv = "Customer," + csv;
      // merge the data with CSV

      this.tableData.data.forEach((row) => {
        const customerData = this.allCustomers.find(
          (x) => x._id === this.isSTBlock ? this.customer_device : this.customer
        );
        let rowdata =
          customerData.first_name +
          " " +
          customerData.last_name +
          " " +
          customerData.email +
          ",";
        this.columns.forEach((e) => {
          if (row[e.prop]) {
            rowdata =
              rowdata +
              (e.prop === "pond" || e.prop === "location"
                ? row[e.prop].name
                : row[e.prop]) +
              ",";
          } else {
            rowdata = rowdata + "" + ",";
          }
        });
        rowdata = rowdata + "\n";

        csv += rowdata;
      });

      csv += "-," + this.sums.join(",") + "\n";

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      // provide the name for the CSV file to be downloaded
      hiddenElement.download =
        "ST_CUSTOMER_Total_Receivables_" +
        this.start_date +
        "_" +
        this.end_date +
        ".csv";
      hiddenElement.click();
    },
    async downloadUserSTTableData(data) {
      let csv = this.STBlockColumns.map((e) => e.label).join(",") + "\n";
      try {
        this.STTableLoading = true;
         var locations = this.isSTBlock ? [...this.location_device] : [...this.location];
        var unAssigned = false;
        const index = locations.indexOf('unAssigned');
        if (index > -1) { // only splice array when item is found
          locations.splice(index, 1);
          unAssigned = true; // 2nd parameter means remove one item only
        }
        const response = await this.fetchCustomerPondShrimptalksBlockDataForDownload({
          order_by: "user_name",
          order_type: "asc",
          user_id: this.isSTBlock ? this.customer_device : this.customer,
          location_ids: locations,
          un_assigned_only: unAssigned,
          get_all: true
          })
          // csv = "Customer," + csv;
          // merge the data with CSV

          response.forEach((row) => {
            let rowdata = "";
            this.STBlockColumns.forEach((e) => {
              if (row[e.prop]) {
                rowdata =
                  rowdata +
                  (e.prop === "pond" || e.prop === "location"
                    ? row[e.prop].name
                    : row[e.prop]) +
                  ",";
              } else {
                if (e.prop === "block_St") {
                  rowdata =
                  rowdata + (row[e.prop] === true
                    ? 'Blocked' : 'Not Blocked') +
                  ",";
                } else {
                  rowdata = rowdata + "" + ",";
                }
              }
            });
            rowdata = rowdata + "\n";

            csv += rowdata;
          });

          // csv += "-," + this.sums.join(",") + "\n";

          var hiddenElement = document.createElement("a");
          hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
          hiddenElement.target = "_blank";

          // provide the name for the CSV file to be downloaded
          hiddenElement.download =
            "ST_CUSTOMER_Total_STs_" +
            this.start_date +
            "_" +
            this.end_date +
            ".csv";
          hiddenElement.click();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.STTableLoading = false;
      }
    },
    async handleSearch(queryObj) {
      if (queryObj.year) {
        this.start_date = moment(queryObj.year)
          .startOf("year")
          .format("YYYY-MM-DD");
        this.end_date = moment(queryObj.year)
          .endOf("year")
          .format("YYYY-MM-DD");

        this.tableData.year = new Date(queryObj.year).getFullYear();
      } else {
      this.rdm__navigateToPathWithCustomerId({ query: { customer: queryObj } })
      }
      await this.handleLoadData();
    },
    async handleCustomerChange() {
      this.isSTBlock ? this.location_device = [] : this.location = [];
      if (this.isSTBlock) {
        await this.fetchUserLocations({ userId: this.isSTBlock ? this.customer_device : this.customer, noLocation: true });
        await this.getUserLocationsData.map(data => {
          // if (data._id !== 'unAssigned') {
            this.location_device.push(data._id);
          // }
        })
      } else {
        await this.handleLoadData();
      }
    },
    handleLocationChange(queryObj) {
      if (!this.isSTBlock) {
        const copyData = this.oldTableData.data;
        if (queryObj.length > 0 && !queryObj.includes('unAssigned')) {
          this.tableData.data = copyData.filter(e => queryObj.includes(e.location._id));
        } else if (queryObj.length > 0 && queryObj.includes('unAssigned')) {
          this.tableData.data = copyData.filter(e => queryObj.includes(e.location._id) || e.location._id === '');
        } else {
          this.tableData.data = this.oldTableData.data;
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let monthCols = this.columns.slice(6, 19);
      monthCols = monthCols.map((e) => e.prop);
      columns.forEach((column, index) => {
        if (
          column.property === "pond" ||
          column.property === "installation_date" ||
          column.property === "start_date" ||
          column.property === "end_date"
        ) {
          sums[index] = "";
          return;
        }
        if (column.property === "location") {
          sums[index] = "Total ShrimpTalks";
          return;
        }

        const values = data.map((item) => {
          if (item[column.property]) {
            const value = item[column.property]; // .toString().replace("$ ", "")
            return Number(value);
          } else {
            return Number(item[column.property]);
          }
        });

        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);

          if (monthCols.indexOf(column.property) > -1) {
            sums[index] = this.getLocaleString(sums[index]);
          }
        } else {
          sums[index] = "-";
        }
      });

      this.sums = sums;
      return sums;
    },

    updateTableData(data) {
      this.userLocationsData = [];
      const tableData = [];
      const monthMap = {
        "01": "jan",
        "02": "feb",
        "03": "mar",
        "04": "apr",
        "05": "may",
        "06": "jun",
        "07": "jul",
        "08": "aug",
        "09": "sep",
        10: "oct",
        11: "nov",
        12: "dec"
      };
      data.forEach((pondData) => {
        if (pondData.location_name !== '' && this.userLocationsData?.filter(e => e.name.toLowerCase() === pondData.location_name ).length === 0) {
            this.userLocationsData.push({ name: this.ftm__capitalize(pondData.location_name), _id: pondData.location_id });
            if (!this.location.includes(pondData.location_id)) {
              this.location.push(pondData.location_id);
            }
            if (!this.location.includes('unAssigned')) {
              this.location.push('unAssigned');
            }
            if (this.userLocationsData?.filter(e => e.name === "No Location" ).length === 0) {
              this.userLocationsData.push({ name: "No Location", _id: 'unAssigned' })
            }
          }
        const obj = {
          location: { name: pondData.location_name, _id: pondData.location_id },
          pond: { name: pondData.pond_name, _id: pondData.pond_id },
          total_sts: pondData.shrimp_talks_count,
          installation_date: pondData.min_installed_date
            ? moment(pondData.min_installed_date).format("DD-MMM-YY")
            : "-",
          start_date: pondData.subscription_start_date
            ? moment(pondData.subscription_start_date).format("DD-MMM-YY")
            : "-",
          end_date: pondData.subscription_end_date
            ? moment(pondData.subscription_end_date).format("DD-MMM-YY")
            : "-",
          total: pondData.total_amount
        };

        pondData.months.forEach((e) => {
          obj[monthMap[e.month]] = e.amount;
        });

        tableData.push(obj);
      });

      return tableData;
    },
    // maybe in feature currency will change based on customer. So implemented this function.
    getCurrencyType() {
      return "$ ";
    },
    getLocaleString(amount = 0) {
      return this.getCurrencyType() + amount.toLocaleString()
    },
    handleEnableFeature(value) {
      // if (type === 'BlockSTTable') {
        this.isSTBlock = value;
        // this.customer = '';
        // this.location = [];
      // }
    },
    handlegroupSettingsClick() {
      this.group_setting_dialogVisible = true;
      // this.customer = '';
      // this.location = [];
    },
    closeGroupSettingsDialog() {
      this.group_setting_dialogVisible = false;
      // this.customer = '';
      // this.location = [];
      if (this.customer_device && this.location_device) {
        this.LocationFilterData();
      }
    },
    async handleDisableFeature(value, STDetails) {
      try {
        const confirmMessage = `Your are ${
            value ? "blocking" : "unblocking"
          } the ShrimpTalk, Are you sure?`;
        await this.$confirm(confirmMessage, "Warning", {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        const payload = {
          shrimp_talk_ids: [STDetails._id],
          block_shrimp_talk: value
        }
        await this.updateBlockStStatus(payload);
        this.$notify({
          type: "success",
          title: "Success",
          message: value ? "ShrimpTalk Blocked" : "ShrimpTalk Unblocked"
        });
        await this.LocationFilterData();
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    handleDate(date) {
      return date ? moment(date).format("DD-MMM-YY") : "-";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.LocationFilterData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.LocationFilterData();
    }
  }
};
</script>

<style lang="scss">
.account-manager-customer-pond-summary {
  height: 100%;
  .el-table--enable-row-hover {
    .el-table__body {
      tr {
        td.actuals {
          background-color: #ebffe2;
        }
        td.projections {
          background-color: #ebebff;
        }
        &:hover > td.el-table__cell {
          background-color: #fafafa;
        }
        &:hover > td.actuals {
          background-color: #dffad2;
        }
        &:hover > td.projections {
          background-color: #dfdffd;
        }
      }
    }
  }
  .el-table th > .cell {
      font-weight: bold;
      color: #233a73;
      line-height: 120%;
      font-size: 11px;
  }
  .el-card__body {
    margin-top: 20px;
  }
  .el-table td.el-table__cell {
    border-bottom: 1px solid #e2e5eb;
  }

  .el-table__body-wrapper {
    height: inherit;
    overflow: auto;
  }
  .el-date-editor {
    .el-input__icon {
      line-height: 29px;
    }
  }
  .el-date-editor.el-input {
    width: 196px !important;
  }
  .pond-details-dialog {
    .el-dialog__header {
      // border: 1px solid #efefef;
      .el-dialog__title {
        font-size: 16px;
        color: #3c3cf8;
      }
    }
    .el-dialog__body {
      padding: 5px 15px !important;
      min-height: auto;
      border-radius: 10px !important;
      background-color: #fafafa;
    }
  }
  .el-dialog {
    border-radius: 10px !important;
  }
  .cust-level-selection-box {
    width: 14rem;
  }
}
</style>

<style lang="scss" scoped>
// This is updated file
.location-name {
  text-transform: capitalize;
}
.text-button {
  padding: 0;
  color: #3c3cf8;
  font-size: inherit;
}
.action-button {
  position: absolute;
  top: 16px;
  right: 55px;
  text-align: right;
  .el-button {
    line-height: 1;
    padding: 5px 8px;
    font-size: 1em;
    height: auto;
  }
}

.table-info {
  padding: 10px;
  font-size: 11px;
}
.table-legends {
  display: flex;
  gap: 10px;
  padding: 8px 10px;
  justify-content: flex-end;
  .icon {
    display: inline-block;
    height: 10px;
    width: 30px;
  }
  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  .projections {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #e0e0fb;
      border: 1px solid #e0e0fb;
      border-radius: 2px;
    }
  }
  .actuals {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #d8fdc8;
      border: 1px solid #d8fdc8;
      border-radius: 2px;
    }
  }
}
.labels-adjustment {
  font-size: 11px;
  margin-bottom: 3px;
  font-weight: bolder;
}
// .toggle-box {
//   margin-left: 6rem !important;
// }
.search-btn {
  .el-button--success {
    padding: 7px;
    height: 26px;
  }
}
</style>
